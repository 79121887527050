<template>
  <div class="search-wrap">
    <div class="search">
      <div class="search-title">
        <search-input v-model="request.queryString" @search="handleSearch" />
        <search-help></search-help>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane :name="item.value" v-for="item in tabs" :key="item.value">
          <template slot="label">{{ item.label
          }}<span class="num">（{{ tabNum(item.key) | format }}）</span></template>
          <div class="search-content" v-loading="activeName === 'All' ? loading : false">
            <template v-if="item.init">
              <component :ref="'search' + item.value" :is="activeComponent(item.value)" :data="data" :tabs="tabs"
                @check-more="onCheckMore" @current-change="onPageCurrentChange"></component>
            </template>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import SearchInput from './components/input.vue';
import SearchHelp from './components/help.vue';
import SearchAll from './components/all.vue';
import SearchCASE from './components/cases.vue';
import SearchLIBRARY from './components/library.vue';
import SearchPRODUCT from './components/products.vue';
import SearchTOOLBOX from './components/toolbox.vue';
import SearchCOURSE from './components/course.vue';
import SearchNEWS from './components/news.vue';
export default {
  components: {
    SearchInput,
    SearchHelp,
    SearchCASE,
    SearchAll,
    SearchLIBRARY,
    SearchPRODUCT,
    SearchTOOLBOX,
    SearchCOURSE,
    SearchNEWS
  },
  data() {
    return {
      items: [],
      request: {
        queryString: '',
        size: 3
      },
      data: {
        content: {},
        total: 0
      },
      tabs: [
        {
          label: '综合',
          value: 'All',
          key: 'ALL',
          page: 1,
          init: 0, // 懒加载
          isNeedUpdate: false // 懒更新
        },
        {
          label: '培训',
          value: 'COURSE',
          key: 'COURSE',
          page: 1,
          init: 0, // 懒加载
          isNeedUpdate: false // 懒更新
        },
        {
          label: '模型库',
          value: 'LIBRARY',
          key: 'LIBRARY',
          page: 1,
          init: 0, // 懒加载
          isNeedUpdate: false // 懒更新
        },
        {
          label: '新闻',
          value: 'NEWS',
          key: 'NEWS',
          page: 1,
          init: 0, // 懒加载
          isNeedUpdate: false // 懒更新
        },
        {
          label: '案例',
          value: 'CASE',
          key: 'CASE',
          page: 1,
          init: 0, // 懒加载
          isNeedUpdate: false // 懒更新
        },
        {
          label: '工具箱',
          value: 'TOOLBOX',
          key: 'TOOLBOX',
          page: 1,
          init: 0, // 懒加载
          isNeedUpdate: false // 懒更新
        },
        {
          label: '产品',
          value: 'PRODUCT',
          key: 'PRODUCT',
          page: 1,
          init: 0, // 懒加载
          isNeedUpdate: false // 懒更新
        },
      ],
      activeName: 'All',
      loading: false
    };
  },
  computed: {
    activeComponent() {
      return value => {
        return 'Search' + value;
      };
    },
    tabNum() {
      return value => {
        if (value == 'ALL') {
          return this.data.total;
        }
        const temp = this.data.content[value];
        if (temp) {
          return temp.totalHits;
        }
        return 0;
      };
    }
  },
  created() {
    const { key, type } = this.$route.query;
    this.request.queryString = key || '';
    this.activeName = type || 'All';
    this.tabs.forEach(item => {
      if (item.value == this.activeName) {
        item.init = 1;
        item.isNeedUpdate = false;
      }
    });
    this.loadData();
    document.documentElement.scrollTop = 0;
  },
  methods: {
    async loadData() {
      this.loading = true;
      const res = await this.$api.searchAll(this.request)
        .catch(() => {
          this.loading = false;
        });
      this.loading = false;
      if (res.code == 200) {
        this.data.content = res.data;
        this.data.total = 0;
        for (let key in this.data.content) {
          this.items = [];
          if (this.data.content[key].searchHits) {
            for (let i = 0; i < this.data.content[key].searchHits.length; i++) {
              this.items[i] = this.data.content[key].searchHits[i].content;
              this.items[i].highlightFields = this.data.content[key].searchHits[i].highlightFields;
            }
            this.data.content[key].items = this.items;
          }
        }
        for (let key in res.data) {
          const temp = res.data[key];
          this.data.total += Number(temp.totalHits);
        }
      }
    },
    // 搜索
    handleSearch(value) {
      // 1.用户输入关键词，只替换路由关键词
      // 2.用户选择联想关键词或者搜索热词，重置所有路由参数
      if (this.request.queryString == value) {
        return;
      }
      // 需要更新
      this.tabs.forEach(item => {
        item.isNeedUpdate = item.value != 'All';
      });
      this.request.queryString = value;
      this.loadData();
      this.changeRoute(value);
      this.updateChild();
    },
    // 切换标签
    handleTabClick() {
      this.tabs.forEach(item => {
        if (item.value == this.activeName) {
          item.init++;
        }
      });
      this.changeRoute();
      this.updateChild();
    },
    // 查看更多
    onCheckMore(value) {
      this.activeName = value;
      this.tabs.forEach(item => {
        if (item.value == this.activeName) {
          item.init++;
        }
      });
      this.changeRoute();
      this.updateChild();
    },
    // 分页变化
    onPageCurrentChange(value) {
      const temp = this.tabs.find(item => item.value == this.activeName);
      if (!temp) {
        return;
      }
      temp.page = value;
    },
    // 改变路由参数
    changeRoute() {
      const temp = this.tabs.find(item => item.value == this.activeName);
      let query = {
        key: this.request.queryString,
        type: temp.value
      };
      if (!query.key) {
        delete query.key;
      }
      this.$router.replace({
        name: 'Search',
        query
      });
    },
    // 更新子组件
    updateChild() {
      const temp = this.tabs.find(item => item.value == this.activeName);
      if (!temp) {
        return;
      }
      if (temp.isNeedUpdate) {
        const el = this.$refs[`search${this.activeName}`];
        if (el) {
          el[0].update();
          temp.isNeedUpdate = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep em {
  color: rgb(15, 106, 217);
  font-style: normal;
}

::v-deep .el-tabs {
  position: relative;
  z-index: 1;

  &__header {
    border-bottom: 1px solid #e9e9ed;
    margin-bottom: 30px;
  }

  &__nav-wrap::after {
    height: 0;
  }

  &__active-bar {
    height: 1px;
  }

  &__item {
    padding: 0 12px;
    height: 50px;
    line-height: 50px;
  }
}

.search-wrap {
  min-height: calc(100vh - 60px);
  // background: url(../../assets/images/bg.png);
  background: #f2f9fc;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 0;
}

.search {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px 180px;
  padding-bottom: 60px;
  border-radius: 4px;
  background: hsla(0, 0%, 100%, 0.6);
  border: 2px solid hsla(0, 0%, 100%, 0.99);

  &-title {
    margin-bottom: 20px;
  }

  &-content {
    min-height: 500px;
  }
}

@media screen and (max-width: 1150px) {
  .search {
    padding: 30px 80px;
  }
}

@media screen and (max-width: 700px) {
  .search {
    padding: 30px;
  }
}
</style>
