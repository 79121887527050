<template>
    <div>
        <!-- <skeleton :count="9" :loading="loading" type="card" :data="data.items">
            <Library :data="data"></Library>
        </skeleton> -->
        <skeleton :count="5" :loading="loading" :data="data.items" type="list">
                <Library :data="data"></Library>
            </skeleton>
        <!-- 分页 -->
        <pagination :pageSizes="[9, 18, 27, 36, 45, 100]" :pageSize="request.pageSize" :currentPage="request.pageIndex"
            :total="data.total" @current-change="pageCurrentChange" @size-change="pageSizeChange">
        </pagination>
    </div>
</template>

<script>
import Library from './block/library.vue';
export default {
  components: {
    Library
  },
  data() {
    return {
      request: {
        queryString: '',
        resourceType: 'LIBRARY',
        pageSize: 5,
        pageIndex: 1
      },
      data: {
        items: [],
        content: [],
        total: 0
      },
      loading: false
    };
  },
  created() {
    const { page } = this.$route.query;
    this.request.pageIndex = Number(page) || 1;
    this.loadData();
  },
  methods: {
    async loadData() {
      this.request.queryString = this.$route.query.key || '';
      this.loading = true;
      const res = await this.$api.search(this.request)
        .catch(() => {
          this.loading = false;
        });
      this.loading = false;
      if (res.code == 200) {
        res.data.searchHits.forEach(item => {
          item.content.createUser = {};
        });
        for (let i = 0; i < res.data.searchHits.length; i++) {
          this.data.items[i] = res.data.searchHits[i].content;
          this.data.items[i].highlightFields = res.data.searchHits[i].highlightFields;
        }
        this.data.total = Number(res.data.totalHits);
      }
    },
    // 当前页变化
    pageCurrentChange(value) {
      let query = Object.assign({}, this.$route.query, {
        page: value
      });
      this.$router.replace({
        name: 'Search',
        query
      });
      this.request.pageIndex = value;
      this.loadData();
      this.$emit('current-change', value);
    },
    // 分页数变化
    pageSizeChange(value) {
      this.request.pageSize = value;
      this.loadData();
    },
    update() {
      this.request.pageIndex = 1;
      this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
