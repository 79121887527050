<template>
  <div class="search-input-wrapper">
    <div class="search-input" @click.stop="">
      <el-input
        prefix-icon="el-icon-search"
        placeholder="请输入内容"
        v-model.trim="request.content"
        @keyup.enter.native="handleSearch()"
      >
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String
  },
  data() {
    return {
      request: {
        content: this.value
      },
      data: {
        content: []
      },
      showHot: false,
      loading: false
    };
  },
  methods: {
    handleSearch(key) {
      if (key) {
        this.request.content = key;
      }
      this.$emit('search', this.request.content, key);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input {
  &__inner {
    padding-left: 42px;
    height: 45px;
    line-height: 45px;
  }
  &__prefix {
    font-size: 18px;
    left: 10px;
    color: $--color-primary;
    line-height: 45px;
  }
}

.search-input {
  position: relative;
}
.hot {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(44, 43, 71, 0.2);
  transform: translateY(100%);
  &-title {
    color: #828282;
    margin-bottom: 20px;
    // font-size: 16px;
  }
  &-content {
    margin-bottom: 20px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        height: 32px;
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 0 12px;
        line-height: 32px;
        cursor: pointer;
        color: #232631;
        background-color: #f5f7fb;
      }
    }
  }
}
</style>
