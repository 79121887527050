<template>
    <div class="body">
        <div style="margin-top: 20px;">您可能需要帮助文档</div>
        <ul>
            <li>
                <el-card>
                    <div class="detail">
                        <div class="image">
                            <img src="@/assets/images/syslab.png" style="height: 100%;" />
                        </div>
                        <div class="title">
                            <a href="/help/SyslabHelp.html">Syslab帮助文档</a>
                        </div>
                    </div>
                </el-card>
            </li>
            <li>
                <el-card>
                    <div class="detail">
                        <div class="image">
                            <img src="@/assets/images/sysplorer.png" style="height: 100%;" />
                        </div>
                        <div class="title">
                            <a href="/help/SysplorerHelp.html">Sysplorer帮助文档</a>
                        </div>
                    </div>
                </el-card>
            </li>
        </ul>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.body {

    /deep/ .el-card {
        box-shadow: none;
        width: 100%;
        border: 1px solid #ddd;
        padding: 0 1.5rem;
        border-radius: 0;
        background-color: #f9f9f9;
    }

    /deep/ .el-card__body {
        padding: 10px 0;
    }

    .detail {
        display: flex;
        height: 40px;
    }

    .image {
        height: 40px;
        width: 40px;
    }

    .title {
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title a {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        font-size: 16px;
        font-weight: 700;
        color: rgb(141, 138, 138);

        &:hover {
            color: $--color-primary;
        }
    }
}

.body ul {
    margin-top: 20px;
    width: 100%;
    display: flex;
}

.body li {
    width: 47%;
    margin-left: 2%;
}

@media screen and(max-width: 700px) {
    .body ul {
        display: block;
    }

    .body li {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }
}</style>
